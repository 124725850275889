/** @jsx jsx */
import { jsx } from '@emotion/react'

import { success } from '../assets/styles/AboutTwo.styles'

import ImgIco from "../assets/images/LP-kingdom/sucesso.svg";

const SuccessContent = () => {
  return (
    <section css={success}>
      <div>
        <img src={ImgIco} alt="imagem" />
        <h1>Obrigado pelo seu interesse</h1>
        <p>Nosso time comercial entrará em contato com você</p>
      </div>
    </section>
  )
}

export default SuccessContent
